<template>
  <div class="sidebar">
    <div :class="{ disabled: disableSidebar }" class="sidebar--top">
      <h1>
        <router-link to="/"
          ><svg height="16" viewBox="0 0 127 20">
            <path
              fill="#002E6E"
              d="M26.208 7.233c-.833 1.316-2.114 3.24-2.114 4.276v8.035h4.227v-8.036c0-1.023-1.315-3.03-2.113-4.276zM28.32 2.96V.328h-4.227v2.633c0 1.032 1.28 2.955 2.114 4.276.798-1.25 2.113-3.258 2.113-4.276V2.96zm11.61 16.916c3.704 0 5.902-1.316 7.88-3.43l-2.691-2.717c-1.51 1.374-2.854 2.252-5.053 2.252-3.292 0-5.575-2.747-5.575-6.04v-.059c0-3.292 2.335-5.986 5.575-5.986 1.924 0 3.43.825 4.916 2.166l2.69-3.101C45.884 1.209 43.718 0 40.09 0c-5.9 0-10.017 4.476-10.017 9.936v.053c0 5.522 4.201 9.887 9.856 9.887zm37.07 0c5.93 0 10.243-4.476 10.243-9.936v-.058C87.242 4.418 82.987 0 77.056 0c-5.93 0-10.241 4.476-10.241 9.936v.053c0 5.469 4.253 9.887 10.183 9.887zm.058-3.9c-3.404 0-5.819-2.747-5.819-6.04v-.054c0-3.292 2.362-5.986 5.766-5.986 3.403 0 5.819 2.747 5.819 6.04v.053c0 3.297-2.362 5.988-5.766 5.988zm12.112 3.572h9.143c4.338 0 7.192-1.755 7.192-5.269v-.054c0-2.578-1.374-3.868-3.594-4.723 1.374-.767 2.526-1.978 2.526-4.144v-.053c0-1.317-.439-2.39-1.316-3.267-1.1-1.099-2.828-1.7-5.025-1.7h-8.922v19.21h-.004zm-23.12 0h-4.942l-4.117-6.151h-.249a1.865 1.865 0 0 1 0-3.731h1.21c2.062 0 3.24-1.1 3.24-2.717v-.052c0-1.813-1.262-2.743-3.324-2.743h-4.2v15.4H49.44V.327h8.783c2.442 0 4.34.687 5.602 1.95 1.073 1.073 1.648 2.579 1.648 4.392v.053c0 3.102-1.674 5.052-4.116 5.956l4.693 6.87zm35.254-5.85v.053c0 1.428-1.126 2.087-2.991 2.087h-5.026v-11.8h4.175c1.786 0 2.774.713 2.774 1.978v.052c0 1.427-1.18 2.03-3.05 2.03h-.934a1.754 1.754 0 1 0-.001 3.51h1.924c2.167.004 3.129.802 3.129 2.092v-.001zm24.667 5.85h-4.418l-1.755-4.312h-4.144a1.867 1.867 0 0 1-1.32-3.185c.35-.35.825-.547 1.32-.547h2.637l-2.553-6.23-5.819 14.274h-4.312L113.84.195h3.901l8.229 19.353zM9.009 13.544a2.399 2.399 0 0 0 3.943 0l4.574-6.547v12.547h4.202V.328H17.17l-6.133 9.205L4.904.328H.35v19.216h4.144V7.078l4.515 6.466zM121.762.645h.679v1.778h.49V.645h.678V.194h-1.848v.451h.001zm3.862-.453-.585.943-.586-.943h-.529v2.231h.485V.976l.621.944h.012l.628-.952v1.455h.484V.193h-.528l-.002-.001z"
            />
          </svg>
        </router-link>
      </h1>
      <ul>
        <li v-for="navItem in navItems" :key="navItem.id">
          <router-link
            v-if="navItem.path"
            :to="navItem.path"
            class="nav-item nav-title"
            :disabled="navItem.disabled"
          >
            <i
              v-if="navItem.icon"
              :class="['fa', `fa-${navItem.icon}`]"
              aria-hidden="true"
            ></i>
            {{ navItem.label }}
          </router-link>

          <template v-if="navItem.children">
            <button
              @click="toggleSubNav(navItem.id)"
              :class="{
                'nav-title': true,
                'nav-active':
                  navOpen === navItem.id ||
                  (!navOpen &&
                    navItem.children.find((item) => item.path === $route.path))
              }"
              :disabled="navItem.disabled"
            >
              {{ navItem.label }}
              <font-awesome-icon
                :icon="['fa', 'fa-chevron-down']"
                aria-hidden="true"
              />
            </button>
            <ul
              v-show="
                navOpen === navItem.id ||
                (!navOpen &&
                  navItem.children.find((item) => item.path === $route.path))
              "
            >
              <li v-for="childItem in navItem.children" :key="childItem.id">
                <router-link
                  v-if="childItem.path"
                  :to="childItem.path"
                  class="nav-item"
                  :disabled="childItem.disabled"
                >
                  <svg
                    v-if="childItem.id === 'cobiomeresultsrelease'"
                    width="36"
                    viewBox="0 0 128 128"
                  >
                    <g fill="currentColor">
                      <path
                        d="M98.7 64.1c0 10.9-7.5 19.6-18.3 19.6-10.9 0-18.5-9-18.5-19.8v-.1c0-11 7.5-19.8 18.3-19.8s18.5 9 18.5 20v.1ZM80.4 35a28.3 28.3 0 0 0-28.6 29v.1A28 28 0 0 0 80.2 93a28.3 28.3 0 0 0 28.6-29 28 28 0 0 0-28.4-29ZM30 83.8C19.7 83.8 12 75 12 64v-.2c0-11 7.6-19.7 18-19.7 6.2 0 10.8 2.8 15.2 7l6.2-7.3A28.2 28.2 0 0 0 30 35.1 28 28 0 0 0 2 64c0 16.3 11.9 29 27.7 29a28 28 0 0 0 22.1-9.9l-6.1-6.4c-4.7 4.4-9 7.1-15.7 7.1Z"
                      />
                      <path
                        d="M92.6 64c3.6 2.3 8.8 5.8 11.6 5.8h15.9a6 6 0 0 0 5.9-6v-.3a5.4 5.4 0 0 0-5.3-5.4h-16.4c-2.9 0-8.3 3.6-11.7 5.9Z"
                      />
                      <path
                        d="M81.1 58h-1.4a5.7 5.7 0 0 0-5.7 6 6 6 0 0 0 5.8 6h1.3c2.8 0 8-3.6 11.6-6-3.4-2.2-8.9-5.8-11.6-5.8Z"
                      />
                    </g>
                  </svg>
                  <svg
                    v-else-if="childItem.id === 'metapanelorders'"
                    width="36"
                    viewBox="0 0 128 128"
                  >
                    <g fill="currentColor">
                      <path
                        d="M47.2 37 30 63 12.8 37H0v54h11.6V56l12.7 18a6.7 6.7 0 0 0 11 0l13-18.3V91H60V37H47.2Zm32 37.8h9c12.1 0 21.8-6.5 21.8-19v-.2c0-11-7.8-18.6-20.7-18.6h-22v54h11.9V74.8Zm0-27h12c5.9 0 11.4 2.8 11.4 8 0 4.7-5.3 8.4-11.2 8.4H79.2V47.7Z"
                      />
                      <path
                        d="M98 56c3.1 2 7.6 5 10 5h14c1.3 0 2.6-.6 3.6-1.5a5 5 0 0 0 1.5-3.6v-.3a4.6 4.6 0 0 0-4.6-4.6h-14.2c-2.6 0-7.2 3-10.2 5Zm-10-5h-1a5 5 0 0 0-3.6 1.5A4.7 4.7 0 0 0 82 56a5 5 0 0 0 1.4 3.5A5 5 0 0 0 87 61h1c2.5 0 7-3 10-5-3-1.8-7.6-5-10-5Z"
                      />
                    </g>
                  </svg>
                  <i
                    v-else-if="childItem.icon"
                    :class="['fa', `fa-${childItem.icon}`]"
                    aria-hidden="true"
                  ></i>
                  {{ childItem.label }}
                </router-link>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </div>
    <div class="sidebar--bottom">
      <button
        :class="{ disabled: disableSidebar }"
        class="nav-link"
        @click="goToProfile"
      >
        <font-awesome-icon :icon="['fa', 'fa-user']" aria-hidden="true" />
        Profile
      </button>
      <button class="nav-link" @click.prevent.stop="goToNewPortal()">
        <font-awesome-icon
          :icon="['fa', 'fa-arrow-up-right-from-square']"
          aria-hidden="true"
        />
        Go To New Portal
      </button>
      <button class="nav-link" @click="logout">
        <i class="icon-account-logout" aria-hidden="true"></i>
        Log Out
      </button>
    </div>
  </div>
</template>

<script>
import {
  TokenService,
  AuthService,
  USER_TYPE,
  CookieService
} from '@microbadevs/library'
import { responseErrorHandler } from '@/services/authService'

export default {
  name: 'Sidebar',
  data: function () {
    return {
      showDropdown: false,
      navOpen: null,
      disableSidebar: null,
      navItems: [
        {
          id: 'dashboard',
          label: 'Dashboard',
          path: '/dashboard'
        },
        {
          label: 'Users',
          children: [
            {
              id: 'customers',
              label: 'Customers',
              path: '/customers',
              icon: 'users'
            },
            {
              id: 'hcp',
              label: 'Health Care Professionals',
              path: '/hcp-professional',
              icon: 'user-md'
            },
            {
              id: 'subscriptions',
              label: 'Subscriptions',
              path: '/due-subscriptions',
              icon: 'dollar'
            },
            {
              id: 'staff',
              label: 'Staff List',
              path: '/staff-list',
              icon: 'user-cog'
            },
            {
              id: 'research',
              label: 'Research',
              path: '/research',
              icon: 'microscope'
            },
            {
              id: 'referrals',
              label: 'Referrals',
              path: '/referrals',
              icon: 'pencil-square-o'
            }
          ]
        },
        {
          id: 'lab',
          label: 'Lab',
          children: [
            {
              id: 'receipt',
              label: 'Receipt',
              path: '/receipt',
              icon: 'receipt'
            },
            {
              id: 'runs',
              label: 'Runs',
              path: '/runs',
              icon: 'vial'
            },
            {
              id: 'plates',
              label: 'Plates',
              path: '/plates',
              icon: 'th'
            },
            {
              id: 'aliquot',
              label: 'Aliquot',
              path: '/aliquot',
              icon: 'vials'
            },
            {
              id: 'lims',
              label: 'LIMS Upload',
              path: '/lims-upload',
              icon: 'file-excel'
            }
          ]
        },
        {
          id: 'logistics',
          label: 'Logistics',
          children: [
            {
              id: 'dispatch',
              label: 'Dispatch',
              path: '/dispatch-station',
              icon: 'truck'
            },
            {
              id: 'orbitm',
              label: 'Orbit-M',
              path: '/orbit-m',
              icon: 'boxes'
            }
          ]
        },
        {
          id: 'marketing',
          label: 'Marketing',
          children: [
            {
              id: 'freekit',
              label: 'Free Kit',
              path: '/free-kit',
              icon: 'gift'
            },
            {
              id: 'promocodes',
              label: 'Promo Codes',
              path: '/promo-code',
              icon: 'credit-card'
            }
          ]
        },
        {
          id: 'finance',
          label: 'Finance',
          children: [
            {
              id: 'invoicedkits',
              label: 'Invoiced Kits',
              path: '/invoiced-kits',
              icon: 'file-invoice-dollar'
            }
          ]
        },
        {
          id: 'inventory',
          label: 'Inventory',
          children: [
            {
              id: 'stock',
              label: 'Stock',
              path: '/inventory',
              icon: 'archive'
            },
            {
              id: 'listkits',
              label: 'Kits',
              path: '/kits',
              icon: 'briefcase-medical'
            },
            {
              id: 'batch',
              label: 'Batch',
              path: '/tube-supplier-batch',
              icon: 'flask'
            },
            {
              id: 'batchlabel',
              label: 'Batch Label',
              path: '/batch-label',
              icon: 'pencil-square-o'
            }
          ]
        },
        {
          id: 'distributors',
          label: 'Distributors',
          children: [
            {
              id: 'languages',
              label: 'Languages',
              path: '/distributor-languages',
              icon: 'language'
            }
          ]
        },
        {
          id: 'support',
          label: 'Support',
          children: [
            {
              id: 'resultsrelease',
              label: 'Results Release',
              path: '/results-release',
              icon: 'upload'
            },
            {
              id: 'cobiomeresultsrelease',
              label: 'Co-Biome Results Release',
              path: '/cobiome-result-release',
              icon: 'notes-medical'
            },
            {
              id: 'samplelookup',
              label: 'Find Sample',
              path: '/sample-lookup',
              icon: 'vial'
            },
            {
              id: 'kitlookup',
              label: 'Find Kit',
              path: '/kit-lookup',
              icon: 'medkit'
            },
            {
              id: 'cobiomereportlookup',
              label: 'Find Co-Biome Report',
              path: '/cobiome-report-lookup',
              icon: 'file'
            },
            {
              id: 'activationcode',
              label: 'Find by Activation Code',
              path: '/activation-code',
              icon: 'barcode'
            },
            {
              id: 'resultsprocess',
              label: 'Results Process',
              path: '/results-process',
              icon: 'redo'
            }
          ]
        },
        {
          id: 'diagnostics',
          label: 'Diagnostics',
          children: [
            {
              id: 'metapanelorders',
              label: 'MetaPanel Orders',
              path: '/metapanel-orders',
              icon: 'notes-medical'
            },
            {
              id: 'metapanelreadyforapproval',
              label: 'MetaPanel Approvals',
              path: '/metapanel-approvals',
              icon: 'check-circle'
            },
            {
              id: 'metapanelresultrelease',
              label: 'MetaPanel Result Release',
              path: '/metapanel-result-release',
              icon: 'file-invoice'
            }
          ]
        }
      ]
    }
  },
  computed: {
    className: function () {
      return this.showDropdown ? 'menu-open' : 'menu-close'
    },
    enableSurveys: function () {
      return process.env.VUE_APP_ENABLE_SURVEYS === 'true' || false // .env by default are strings
    },
    isAdmin: function () {
      return this.$route.query?.admin
    },
    authServiceComputed: function () {
      const { accessToken, refreshToken } = TokenService.getTokenDetails()
      return new AuthService({
        accessToken,
        refreshToken,
        responseErrorHandler
      })
    }
  },
  created() {
    window.addEventListener('scroll', this.closeMore)
  },
  async mounted() {
    this.disableSidebar = await this.isUserEnabling2fa()
  },
  methods: {
    toggleSubNav(type) {
      this.navOpen = type !== this.navOpen ? type : null
    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
    /**
     * @param pathName string - additional path name to append to staff hub url
     */
    goToNewPortal(pathName) {
      const { refreshToken } = TokenService.getTokenDetails()
      // save token with the cookie service
      CookieService.saveRefreshTokenAsCookie(refreshToken)

      // create the new url
      const origin = window.location.origin
      let newUrl = origin.replace('//staff-', '//staff-new-')

      if (typeof pathName === 'string') newUrl = newUrl + pathName
      window.location.replace(newUrl)
    },
    goToProfile: function () {
      this.$router.push({ name: 'Profile' })
    },
    toggleMore: function () {
      this.showDropdown = !this.showDropdown
    },
    closeMore: function () {
      this.showDropdown = false
    },
    isUserEnabling2fa: async function () {
      const is2FAEnabledForUser = await this.authServiceComputed.is2FAEnabled(
        USER_TYPE.USER
      )
      return (
        this.$route.name === 'two-factor-authentication-set-up' &&
        !is2FAEnabledForUser
      )
    }
  },
  watch: {
    async '$route.path'() {
      if (this.disableSidebar === null || this.disableSidebar) {
        const is2faEnabling = await this.isUserEnabling2fa()
        this.disableSidebar = is2faEnabling
      }
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1rem;
  line-height: 1;
  margin: 0;
  padding: 0;
}

h1 a {
  background-color: transparent !important;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.nav-active {
  color: #ff8708 !important;
}

.sidebar ul > li > ul {
  border-bottom: 1px solid #eee;
}

.template-page .sidebar ul svg {
  margin-right: -3px;
  width: 1.6rem;
  flex: 0 0 1.6rem;
}
</style>
